import { Component, HostListener } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'Foto-GIS';
  temp: string;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.setCookiesBannerPosition();
  }

  constructor(
    public translate: TranslateService,
    private ccService: NgcCookieConsentService,
    public meta: Meta,
    public title: Title
  ) {
    translate.addLangs(['en', 'es', 'fr']);
    let temp = localStorage.getItem('lang');

    if (temp == null || temp == undefined) {
      const lang = translate.getBrowserLang();

      if (lang == undefined || (lang !== 'en' && lang !== 'es' && lang !== 'fr')) {
        translate.setDefaultLang('en');
        this.translate.use('en');
        let desc = this.translate.instant('seo.DESCRIPTION');
        this.meta.addTag({ name: 'description', content: desc });
        this.title.setTitle(this.translate.instant('seo.TITLE'));
        localStorage.setItem('lang', 'en');
      }
      else {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        let desc = this.translate.instant('seo.DESCRIPTION');
        this.meta.addTag({ name: 'description', content: desc });
        this.title.setTitle(this.translate.instant('seo.TITLE'));
        localStorage.setItem('lang', lang);
      }
    }
    else {
      this.translate.setDefaultLang(temp);
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      let desc = this.translate.instant('seo.DESCRIPTION');
      this.meta.updateTag({ name: 'description', content: desc })
      this.title.setTitle(this.translate.instant('seo.TITLE'));
    });
  }

  ngOnInit() {
    this.translate//
      .get(['cookie.message', 'cookie.allow', 'cookie.link', 'cookie.deny'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  setCookiesBannerPosition() {
    let cookiesBanner = document.querySelector('.cc-banner') as HTMLElement;
    if (cookiesBanner) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        cookiesBanner.style.marginBottom = '65px';
      } else {
        cookiesBanner.style.marginBottom = '0px';
      }
    }
  }

}
