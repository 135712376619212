import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component'

@Component({
  selector: 'app-organizer-dialog',
  templateUrl: './organizer-dialog.component.html',
  styleUrls: ['./organizer-dialog.component.scss']
})
export class OrganizerDialogComponent implements OnInit {

  constructor(
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<OrganizerDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  openModalContact() {
    this.closeModal();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = "app-dialog-component";
    dialogConfig.data = { preventAddingClass: true };
    const modalDialog = this.matDialog.open(ContactDialogComponent, dialogConfig);
  }

  closeModal() {
    this.dialogRef.close();
  }

}
